/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Varsovie"), React.createElement("p", null, "Pour se loger, Varsovie s’articule en quatre quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/warsaw/staremiastowarszawa.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Stare Miasto"), " : la vieille ville, de la place du marché à la place du château."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/warsaw/srodmiescie.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Srodmiescie"), " : le centre-ville qui regroupe la vieille ville, la nouvelle ville et les attractions majeures."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/warsaw/praga-poludnie.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Praga"), ", à l’est de la Vistule : quartier jeune et bohème en pleine gentrification."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/pl/warsaw/wola.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Wola"), ", à l’ouest : le quartier de Varsovie dédié aux affaires."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
